import React, { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import { useContext } from "react";
import {
  Add, Circle, VisibilityTwoTone,
} from "@mui/icons-material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context";

const Dashboard = () => {
  const {
    state: { usersList },
    getUserProfiles,
  } = useContext(UserContext);
  
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [pageSize, setPageSize] = React.useState(10);

  useEffect(() => {
    getUserProfiles();
  }, []);

  const columns = [
    {
      field: "",
      headerName: "Azioni",
      width:100,
      renderCell: (params) => {
        return (
          <Stack direction={"row"} justifyContent={"center"}>
            <IconButton
              disabled={params.row.state === "disabled"}
              onClick={() => navigate(`/dashboard/user/${params.row._uuid}`)}
            >
              <VisibilityTwoTone />
            </IconButton>
            {}
          </Stack>
        );
      },
    },
    {
      field: "state",
      headerName: "Stato",
      width:100,
      editable: false,
      renderCell: (params) => (<Stack direction={"row"} justifyContent={"center"}><Circle color={params.row.state === "enabled" ? "success" :"error"} fontSize="xs"></Circle> </Stack>)
    },
    {
      field: "username",
      headerName: "User name",
      flex: 1,
      editable: false,
    },
    {
      field: "first_name",
      headerName: "Nome",
      flex: 1,
      editable: false,
    },
    {
      field: "last_name",
      headerName: "Cognome",
      flex: 1,
      editable: false,
    },
  ];


  return (
    <Box sx={{ width: "100%" }}>
      <ButtonGroup variant="contained">
        <Button
          startIcon={<Add />}
          onClick={() => navigate(`/dashboard/user/new`)}
          color="secondary"
        >
          Aggiungi Utente
        </Button>
      </ButtonGroup>
      <Stack direction={"row"} spacing={2}>
        <TextField
          margin="normal"
          fullWidth
          label="Ricerca utente"
          autoComplete="off"
          autoFocus
         /*  onChange={(e) => filterList (e.target.value, lists_domain)} */
        />

      </Stack>

      <DataGrid
        autoHeight
        rows={usersList}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 25, 50, 100]}
        disableSelectionOnClick
        disableRowSelectionOnClick={false}
      />
    </Box>
  );
};

export default Dashboard;
